// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

.gap-3{
  gap: 0.75rem !important;
}

// todo: temp c-dark-theme plumbing for select option
.c-dark-theme option {
  background-color: #34343b;
  border: 1px solid #23282c;
}

.c-dark-theme {
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    color: #ffffff !important;
  }

  .bg-light {
    background-color: #34343b !important;
  }

  .bg-white {
    background-color: #24252f !important;
  }

  .text-black {
    color: black !important;
  }

  .alert-info {
    color: #4799eb;
  }

  .custom-control-label::before {
    background-color: gray;
  }

  .ql-toolbar span,
  .ql-stroke {
    color: white !important;
    stroke: white !important;
  }

  .btn-outline-primary {
    color: #4799eb !important;
    border-color: #4799eb !important;
  }
}

#hs-eu-cookie-confirmation {
  background-color: #34343b !important;
  div {
    div,
    p {
      font-size: 1rem !important;
      margin: 10px 0 !important;
      color: #ffffff !important;
    }
    background-color: #34343b !important;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.react-datepicker__input-container input {
  border: none;
  border-bottom: 0.1px solid hsla(0, 0%, 20%, 0.5);
  background-color: transparent !important;
  width: 110px;
}

.react-datepicker__close-icon::after {
  font-size: 14px;
}
