// Here you can add other styles

.border-black-left {
  border-left: 1px solid #959dca;
}

.text-black {
  color: black !important;
}

.c-dark-theme .text-black {
  color: white !important;
}

.text-golden {
  color: #df9900;
}

.bg-golden {
  background-color: #df9900;
}

.bg-golden:hover {
  background-color: #c28d1c;
}

.font-bold {
  font-weight: 600;
}

.font-regular{
  font-weight: 400!important;
}

.font-semibold {
  font-weight: 500;
}

.c-dark-theme .alert-info {
  background-color: var(--clr-table-dark-2);
  border-color: #4638c2;
  color: white;

  button {
    color: white;
    border-color: white;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--color-secondary-dark);
  border-radius: 50%;
  display: inline-block;
}

.invisible{
  visibility: hidden;
}
