:root {
  --header-height: 104px;
  --footer-height: 50px;
  --main-padding-top: 32px;
  --color: #181743;
  --bg-color: #f7fcff;
  --color-secondary: #959da9;
  --color-secondary-dark: #6b6b6b;
  --border: 0.1px solid hsla(0, 0%, 20%, 0.5);
  --transition: all 0.5s ease-in-out;
  --clr-table-light-1: white;
  --clr-table-light-2: whitesmoke;
  --clr-table-header: #eeeeee;
  --clr-table-dark-1: #1d1e29;
  --clr-table-dark-2: #181743;
  --clr-table-header-dark: #1b1b1b;
  --clr-border-light: #d8dbe0;
  --clr-border-dark: #414141;
}

// Variable overrides
$tooltip-bg: #eeeeee;
$tooltip-color: black;
$tooltip-font-size: 14px;

// Background overrides
$body-bg: var(--bg-color);
$body-color: var(--color);

// Color overrides
// $primary: #181743;

$theme-colors: (
  "primary": #181743,
  "danger": #ff4136,
  "secondary": #959da9,
  "secondary-dark": #6b6b6b,
  "info": #006bd7,
);

// Font Family overrides
@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600&display=swap");
$font-family-base: "Hind Madurai", sans-serif;
